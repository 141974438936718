@use "../global" as g;

.time-stamp-wrap {
  position: relative;
  padding-top: 10px;
  width: 79px;

  @include g.media-query-sp {
    width: 36px;
  }

  &::before {
    border-left: 2px dashed g.$point-color;
    content: '';
    position: absolute;
    width: 0;
    top: 20px;
    left: calc(100% + 50px);
    height: 100%;
    transform: translateX(-50%);

    @include g.media-query-sp {
      top: 15px;
      left: calc(100% + 20px);
    }
  }

  &::after {
    content: '';
    background-color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid g.$point-color;
    position: absolute;
    z-index: 1;

    @include g.media-query-pc {
      top: 10px;
      left: calc(100% + 40px);
    }
  
    @include g.media-query-sp {
      top: 5px;
      right: -25px;
      width: 10px;
      height: 10px;
      border: 2px solid g.$point-color;
      top: 8px;
    }
  }
}
