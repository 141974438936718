@use "../global" as g;

body {
  @include g.media-query-pc {
    min-width: 1180px;
  }
}

.wrapper {
  overflow-x: hidden;
}

.inner {
  padding: 80px 20px;
  margin: 0 auto;
  max-width: 1180px;

  &:not(.section-inner-top) {
    @include g.media-query-sp {
      padding: 40px calc((30 / 750) * 100vw);
    }
  }
}

.section-best-thing {
  .inner {
    @include g.media-query-sp {
      padding-right: calc((30 / 750) * 100vw);
      padding-left: calc((30 / 750) * 100vw);
      padding-bottom: 40px;
    }
  }
}

.section-inner-top {
  padding-top: 150px;

  @include g.media-query-sp {
    padding-top: 110px;//psdデザインだと86px
  }
}