@use "../global" as g;

@include g.media-query-pc { /* pc */
  .u-hidden-pc {
    display: none;
  }
}

@include g.media-query-sp { /* sp */
  .u-hidden-sp {
    display: none;
  }
}

.letter-spacing-01 {
  letter-spacing: -.25em;
}