html {
  font-size: 62.5%;// font-sizeの10pxを1remにするため
}

body {
  font-family: 'Noto Serif JP', serif;
  font-feature-settings: "palt";
}

a {
  &:hover {
    filter: brightness(80%);
    transition: .3s ease-in-out;
  }
}