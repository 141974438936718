@use "../global" as g;

#modal {
  display: none;
}

.modaal-content-container {
  overflow-X: hidden;
}

.em-color {
  color: g.$accent-color;
  font-size: 1.33em;
  font-style: normal;
}

.kv-wrapper {
  background-size: auto;
  background-position: top;
  background-repeat: no-repeat;
  z-index: 1;
  position: relative;
  pointer-events: none;

  @include g.media-query-pc {
    // background-image: url(../img/kv/bg_kv.webp);
    height: 1080px;
  }

  @include g.media-query-sp {
    // background-image: url(../img/kv/bg_kv_sp.webp);
    height: 627px;
    background-size: auto 100%;
  }

  .kv-bg {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 2884px;

    @include g.media-query-sp {
      min-width: 750px;
      width: 100%;
    }
  }
}

.kv-item {
  position: absolute;

  &-heading {

    @include g.media-query-pc {
      top: 0;
      width: 661px;
      top: 85px;
      left: calc(50% - 617px);
    }

    @include g.media-query-sp {
      max-width: 340px;
      width: 100%;

      @include g.center
    }
  }


  &-01 {
    top: 480px;
    right: calc(50% - 641px);

    @include g.media-query-sp {
      max-width: 278px;
      width: 100%;
      right: 0;
    }
  }

  &-02 {
    top: 0;

    @include g.media-query-pc {
      right: calc(50% - 727px);
    }

    @include g.media-query-sp {
      left: -3px;
      max-width: 185px;
      width: 60%;
    }
  }
}

.section-best-thing {
  background-size: auto 100%;
  background-position: top;
  background-repeat: no-repeat;

  @include g.media-query-pc {
    margin-top: -290px;
    background-image: url(.././img/main/bg_01.png);
  }

  @include g.media-query-sp {
    margin-top: -27px;
    background-image: url(.././img/main/bg_sp_01.png);
    background-size: 100% 100%;
  }

  .section-heading-wrap {
    margin-top: 60px;
  }
}

.section-heading {

  @include g.media-query-sp {
    img[src*="heading_01"] {
      width: calc((561 / 750) * 100vw);
    }

    img[src*="heading_02"] {
      width: calc((690 / 750) * 100vw);
    }

    img[src*="heading_03"] {
      width: calc((678 / 750) * 100vw);
    }

    img[src*="heading_sp_04"] {
      width: calc((496 / 750) * 100vw);
    }

    img[src*="title-01"] {
      width: calc((474 / 750) * 100vw);
    }

    img[src*="heading_05"] {
      width: calc((354 / 750) * 100vw);
    }

    img[src*="heading_06"] {
      width: calc((677 / 750) * 100vw);
    }

    img[src*="heading_sp_07"] {
      width: calc((495 / 750) * 100vw);
    }
  }
}

.section-heading-sub {
  position: relative;
  z-index: 1;

  @include g.media-query-sp {
    img[src*="title-02"] {
      width: calc((481 /750) * 100vw);
    }
  }

  &::before,
  &::after {
    position: absolute;
    content: '';
    background-repeat: no-repeat;
    background-size: 100%;
    bottom: 1px;

    @include g.media-query-sp {
      width: 78px;
      height: 24px;
      top: 25px;
    }
  }

  &::before {
    background-image: url(.././img/main/mountain_01.png);
    width: 177px;
    height: 54px;
    left: 168px;

    @include g.media-query-sp {
      width: calc((155 / 750) * 100vw);
      height: 24px;
      left: -15px;
    }
  }

  &::after {
    background-image: url(.././img/main/mountain_02.png);
    width: 274px;
    height: 63px;
    right: 71px;

    @include g.media-query-sp {
      right: -40px;
      width: calc((176 / 750) * 100vw);
      height: 28px;
    }
  }
}

.announce-image {
  margin: 30px auto 0;

  img {
    margin: auto;
  }

  @include g.media-query-pc {
    width: 647px;
  }

  @include g.media-query-sp {
    margin-top: 20px;

    img[src*="privilege_sp"] {
      width: calc((571 / 750) * 100vw);
    }
  }
}

.section-inner-top {
  @include g.media-query-sp {
    img[src*="button_01"] {
      width: calc((690 / 750) * 100vw);
    }
  }

  .button {
    margin: auto;
  }
}

.section-heading {
  position: relative;
  z-index: 1;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 166px;
    height: 101px;
    background-repeat: no-repeat;

    @include g.media-query-sp {
      width: 83px;
      height: 50px;
      background-size: 100%;
      top: -9px;
    }
  }

  img {
    position: relative;
    z-index: 1;
    margin: auto;
  }
}

.heading-decoration-blue {

  &::before {
    top: 0;
    left: 20px;
    background-image: url(.././img/main/decoration_01.png);

    @include g.media-query-sp {
      left: -5px;
    }
  }

  &::after {
    top: 132px;
    right: 20px;
    background-image: url(.././img/main/decoration_02.png);

    @include g.media-query-sp {
      right: -5px;
    }
  }
}

.heading-decoration-orange {

  &::before,
  &::after {
    @include g.media-query-sp {
      width: 60px;
      height: 40px;
      background-size: 100%;
    }
  }

  &::before {
    top: 0;
    left: 0;
    background-image: url(.././img/main/decoration_09.png);

    @include g.media-query-sp {
      top: -21px;
      background-image: url(.././img/main/decoration_11.png);
      top: 16px;
      left: -10px;
    }
  }

  &::after {
    top: 89px;
    right: 0;
    background-image: url(.././img/main/decoration_10.png);

    @include g.media-query-sp {
      top: 46px;
      right: -10px;
    }
  }
}

.heading-decoration-blue-02 {

  &::before {
    @include g.media-query-sp {
      background-image: url(.././img/main/decoration_03.png);
      height: 107px;
      width: 67px;
      left: 0;
      top: -5px;
    }
  }

  &::after {

    @include g.media-query-pc {
      top: 89px;
    }

    @include g.media-query-sp {
      background-image: url(.././img/main/decoration_05.png);
      height: 111px;
      width: 93px;
      top: -8px;
    }
  }
}

.section-spot {

  .heading-decoration-blue {

    &::before {

      top: 50px;

      @include g.media-query-sp {
        top: -26px;
      }
    }

    &::after {
      top: 139px;
      right: -10px;
    }
  }
}

// .heading-decoration-blue-02 {

//   &::before {
//     top: -10px;
//   }
// }

.section-time-schedule {

  .heading-decoration-blue-02 {

    &::before {
      @include g.media-query-pc {
        top: -53px;
      }
    }

    @include g.media-query-pc {
      &::after {
        top: 37px;
      }
    }
  }
}


.section-heading>img {
  margin: auto;
}

.section-heading-sub {
  margin-top: 60px;

  @include g.media-query-sp {
    margin: 25px auto 20px;
  }

  >img {
    margin: auto;
  }
}

.section-heading-wrap {
  text-align: center;
}

.section-lead {
  font-size: 1.8rem;
  font-family: g.$font-noto-sans;
  color: g.$font-base-color;
  line-height: 1.333;
  margin-top: 30px;

  @include g.media-query-sp {
    font-size: 1.5rem;
    margin-top: 20px;
  }
}

.comment-list {
  font-weight: g.$font-weight-regular;
  background-color: #fff;
  position: relative;

  @include g.media-query-pc {
    width: calc((100% / 2) - 15px);
    padding: 0 30px 37px;
  }

  @include g.media-query-sp {
    padding: 0 15px 25px;
  }

  &-wrap {
    display: flex;

    @include g.media-query-pc {
      flex-wrap: wrap;
      margin-top: 70px;
      gap: 50px 30px;
    }

    @include g.media-query-sp {
      margin-top: 40px;
      flex-direction: column;
      gap: 35px;
    }

  }

  &-text {
    font-family: g.$font-noto-sans;
    color: g.$font-base-color;
    font-size: 1.8rem;
    line-height: 1.333;
    margin-top: 17px;
    font-weight: g.$font-weight-regular;

    @include g.media-query-sp {
      font-size: 1.5rem;
      @include g.lhCrop(1.4);
      line-height: 1.4;
      margin-top: 15px;
    }
  }

  &-item {
    margin-top: -10px;

    @include g.media-query-sp {
      width: 65px;
      align-self: flex-start;
      margin-top: -5px;
    }
  }

  &-inner {
    display: flex;
    gap: 20px;
    align-items: flex-start;

    @include g.media-query-sp {
      gap: 10px;
    }
  }
}

.speech-bubble {
  background-color: g.$point-color;
  padding: 16px 30px;
  color: #fff;
  font-family: g.$font-noto-serif;
  font-weight: g.$font-weight-medium;
  font-size: 2.2rem;
  border-radius: 10px;
  position: relative;
  margin-top: -20px;
  line-height: 1.364;
  letter-spacing: -.04em;

  @include g.media-query-sp {
    font-size: 1.7rem;
    padding: 12px;
    width: calc((480 / 750) * 100vw);
  }

  ::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 38px;
    bottom: -10px;
    border-style: solid;
    border-color: transparent transparent transparent #68a095;
    border-width: 7px 0 7px 21.32px;
    transform: rotate(110deg);
  }

  p {
    @include g.lhCrop(1.364);
  }
}

.section-spot {
  background-color: #f5f9fd;
}

.section-contents {
  display: flex;
  gap: 30px;
  margin: 40px auto 80px;
  position: relative;
  z-index: 1;

  @include g.media-query-pc {
    align-items: center;
  }

  @include g.media-query-sp {
    flex-direction: column-reverse;
    margin: 17px auto 40px;
    gap: 25px;
  }

  &-image-wrap {
    display: flex;

    @include g.media-query-pc {
      width: 628px;
    }
  }

  &-text {
    font-size: 2rem;
    font-family: g.$font-noto-serif;
    color: #181818;
    font-weight: g.$font-weight-semibold;
    line-height: 1.7;
    letter-spacing: -.05em;

    +.section-contents-text {
      margin-top: 25px;
    }

    @include g.media-query-pc {
      width: 478px;
    }

    @include g.media-query-sp {
      font-size: 1.5rem;
      line-height: 1.467;
    }

    &-bottom {
      @include g.media-query-pc {
        margin-top: 40px;
      }
    }
  }

  &-image-01 {
    border-radius: 20px;
  }

  &-image-02 {
    transform: translateX(-55px);
  }
}

.line-image {
  @include g.media-query-sp {
    width: 301px;
    margin: auto;
  }

  >img {
    margin: auto;
  }
}


.section-reserve {
  background-color: #f3e7e1;
}

.section-time-schedule {
  background-color: #f5f9fd;
}

.label {
  background-color: g.$point-color;
  width: 100%;
  margin: 40px 0;
  padding: 8px 0;

  @include g.media-query-sp {
    padding: 7px 0;
    margin: 0 0 20px 0;
  }

  .day-one,
  .day-two {
    margin: auto;

    @include g.media-query-sp {
      width: 50px;
    }
  }
}

.schedule {

  &-body {
    display: flex;
    flex-direction: column;

    @include g.media-query-pc {
      padding-left: 65px;

      &-1day {
        padding-bottom: 30px;
      }
    }
  }

  &-course {
    position: relative;

    &-05 {

      .guide {

        &-image {
          top: -29px;
        }
      }
    }

    &-06 {

      .guide-contents {
        @include g.media-query-pc {
          padding-bottom: 0;
        }
      }

      .time-stamp-wrap {

        &::before {
          display: none;
        }
      }
    }

    &-08 {

      .guide {

        &-text {
          margin-bottom: 20px;
        }

        &-contents {

          @include g.media-query-pc {
            padding-bottom: 160px;
          }
        }
      }
    }

    &-09 {

      .guide {
        @include g.media-query-pc {
          width: auto;
        }
      }

      .list-card-description {
        margin: 15px 0;

        @include g.media-query-sp {
          font-size: 1.5rem;
        }
      }

      .list-card-schedule {
        gap: 24px;

        @include g.media-query-pc {
          :nth-of-type(2) {
            width: 430px;
          }
        }
      }

      .list-card-image-wrap {

        img {
          border-radius: 0;
        }
      }
    }

    &-11 {
      .guide-contents {
        @include g.media-query-pc {
          padding-bottom: 0;
        }
      }

      .time-stamp-wrap {

        &::before {
          display: none;
        }
      }
    }
  }

  &-item-wrap {
    display: flex;
  }
}

img[src*="time_06"] {
  width: 65px;
  margin-left: auto;

  @include g.media-query-sp {
    width: 30px;
  }
}

.guide {
  position: relative;

  @include g.media-query-pc {
    margin: 0 30px 0 120px;
    width: 546px;
  }

  &-contents {
    padding-bottom: 72px; //80px広い？

    @include g.media-query-sp {
      padding-left: 39px;
      flex: 1;
      padding-bottom: 35px;
    }
  }

  &-em {
    font-family: g.$font-noto-serif;
    color: g.$font-base-color;
    line-height: 1.267;
    font-weight: g.$font-weight-medium;
    @include g.lhCrop(1.267);

    @include g.media-query-pc {
      font-size: 3rem;
    }

    @include g.media-query-sp {
      font-size: 1.8rem;
      letter-spacing: -.07em;
    }
  }

  &-text {
    font-size: 1.7rem;
    font-family: g.$font-noto-sans;
    color: g.$font-base-color;
    line-height: 1.353;
    font-weight: g.$font-weight-regular;
    margin-top: 20px;
    @include g.lhCrop(1.353);

    @include g.media-query-pc {
      width: 546px;
    }

    @include g.media-query-sp {
      font-size: 1.5rem;
      margin-top: 15px;
    }
  }

  &-image {

    @include g.media-query-pc {
      position: absolute;
      right: 0;
      top: 0;
      width: 300px;
    }

    @include g.media-query-sp {
      margin-top: 15px;
    }

    :nth-child(2) {
      margin-top: 5px;
    }

    img {
      border-radius: 20px;

      @include g.media-query-sp {
        width: 100%;
        border-radius: 10px;
      }
    }
  }
}

.table {
  font-family: g.$font-noto-sans;

  dl {
    display: flex;
    align-items: center;
    gap: 11px;
    margin-top: 5px;

    &:first-child {
      margin-top: 0;
    }

    @include g.media-query-sp {
      margin-top: 8px;
      gap: 5px;
    }
  }

  &-title {
    background-color: g.$point-color;
    color: #fff;
    font-size: 1.6rem;
    font-weight: g.$font-weight-medium;
    line-height: 1.438;
    text-align: center;
    width: 80px;
    border-radius: 5px;
    padding: 4px 0;
    align-self: flex-start;

    @include g.media-query-sp {
      font-size: 1.4rem;
      line-height: 0.821;
      width: 97px;
      border-radius: 2.5px;
      padding: 7px 0;

      &:not(.hotel-info .table-title) {
        width: 67px;
      }
    }
  }

  &-info {
    line-height: 1.353;
    font-size: 1.7rem;
    font-weight: g.$font-weight-regular;

    @include g.media-query-sp {
      font-size: 1.4rem;
      flex: 1;
    }
  }

  tbody {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

.section-impression {
  background-image: url(.././img/main/bg_01.png);
  background-size: auto;
  background-repeat: no-repeat;

  @include g.media-query-sp {
    background-image: url(.././img/main/bg_sp_02.png);
  }
}

.message {
  font-family: g.$font-noto-sans;
  color: g.$font-base-color;
  line-height: 1.941;
  font-weight: g.$font-weight-regular;
  border-bottom: 1px dashed #ccc;
  display: inline;
  padding-bottom: 2px;

  @include g.media-query-pc {
    font-size: 1.7rem;
  }

  @include g.media-query-sp {
    font-size: 1.5rem;
  }

  &-column {
    position: relative;

    @include g.media-query-pc {
      justify-content: space-between;
      margin-top: -94px;
      display: flex;
    }

    @include g.media-query-sp {
      margin-top: 25px;
    }

    :nth-of-type(2) {
      @include g.media-query-sp {
        margin-top: 20px;
      }
    }
  }

  &-wrap {
    background-color: #fff;
    padding: 40px 40px 50px 40px;

    @include g.media-query-pc {
      width: 340px;
    }
  }

  &-title {
    background-color: g.$point-color;
    color: #fff;
    font-family: g.$font-noto-serif;
    line-height: 1.364;
    font-weight: g.$font-weight-medium;
    position: relative;

    @include g.media-query-pc {
      font-size: 2.2rem;
      padding: 11px 18px;
      margin-bottom: 23px;
    }

    @include g.media-query-sp {
      padding: 22px 0;
      text-align: center;
      font-size: 1.8rem;
    }

    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      right: 75px;
      bottom: -13px;
      border-style: solid;
      border-color: transparent transparent transparent #68a095;
      border-width: 7px 0 7px 21.32px;
      transform: rotate(65deg);

    }
  }
}

.message-column .message-wrap:nth-of-type(2) {
  .message-title::after {
    left: 75px;
    transform: rotate(115deg);
  }
}


.pair-people-image-01 {
  width: 263px;
  bottom: 0;
  left: 26%;
  z-index: 1;

  @include g.media-query-pc {
    position: absolute;
  }
}

.pair-people-image-02 {
  width: 315px;
  bottom: 0;
  right: 26%;

  @include g.media-query-pc {
    position: absolute;
  }
}

.section-others {
  background-image: url(.././img/main/bg_02.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: top;

  @include g.media-query-sp {
    background-image: url(.././img/main/bg_sp_03.png);
    background-size: 100% 100%;
  }
}

.img {
  background-image: url("img.png");
  opacity: 0.4;
  position: absolute;
  left: -904px;
  top: 790px;
  width: 5021px;
  height: 1193px;
  z-index: 586;
}

//modal

.modal-inline {
  display: none;
}

.modaal-container {
  max-width: 1140px;
}

.modaal-inner-wrapper {
  @include g.media-query-sp {
    padding: 80px 15px;
  }
}

.modaal-content-container {
  background-color: #f5f9fd;

  @include g.media-query-pc {
    padding: 80px 80px 100px 80px;
  }

  @include g.media-query-sp {
    padding: 75px calc((30 / 750) * 100vw) 40px;

  }
}

//modal閉じるボタン
.modaal-close {
  display: none;
}

.modal-close {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  width: 60px;
  height: 60px;

  @include g.media-query-sp {
    right: 15px;
    top: 15px;
    width: 50px;
    height: 50px;
  }
}

.modal-contents {
  .button-detail {


    @include g.media-query-pc {
      width: 545px;
    }

    @include g.media-query-sp {
      max-width: 630px;
      width: 100%;
      // padding: 13px 0;
    }
  }
}

.modal-button {
  @include g.lhCrop(1.15);

  @include g.media-query-sp {
    line-height: 0.938;
    @include g.lhCrop(0.938);
  }
}

.modal-button-top {

  @include g.media-query-pc {
    margin: 30px auto 40px auto;
  }

  @include g.media-query-sp {
    margin: 20px auto;
  }
}

.modal-button-bottom {

  @include g.media-query-pc {
    margin: 40px auto 60px auto;
  }

  @include g.media-query-sp {
    margin: 25px auto 50px auto;
  }
}

.column {
  display: flex;


  &-tree {
    gap: 10px;

    @include g.media-query-sp {
      flex-direction: column;
    }

    img {
      @include g.media-query-pc {
        width: 320px;
      }
    }
  }
}

.modal-heading-01 {

  .section-lead {
    margin-top: 40px;

    @include g.media-query-sp {
      text-align: left;
      margin-top: 25px;
    }
  }

  .section-heading {

    @include g.media-query-sp {
      max-width: 230px;
      margin: auto;
      width: 100%;
    }
  }
}

.modal-heading-02 {

  .section-heading {

    @include g.media-query-pc {
      padding: 80px 0 50px 0;
    }

    @include g.media-query-sp {
      padding: 50px 0 30px 0;
      max-width: 309px;
      margin: auto;
      width: 100%;
    }
  }
}

.modal-heading-01 {
  .heading-decoration-blue {

    &:before {
      top: -14px;
      left: 60px;

      @include g.media-query-sp {
        background-image: url(.././img/main/decoration_06.png);
        width: 63px;
        height: 50px;
        top: -35px;
        left: -42px;
      }
    }

    &::after {

      top: 6px;
      right: 61px;

      @include g.media-query-sp {
        background-image: url(.././img/main/decoration_07.png);
        width: 53px;
        height: 61px;
        top: 8px;
        right: -43px;
      }
    }
  }
}

.modal-heading-02 {
  .heading-decoration-blue {

    &::before {
      top: 10px;
      left: 0;

      @include g.media-query-sp {
        background-image: url(.././img/main/decoration_08.png);
        width: 49px;
        height: 89px;
      }
    }

    &::after {
      top: 60px;
      right: 0;

      @include g.media-query-sp {
        background-image: url(.././img/main/decoration_04.png);
        width: 83px;
        height: 78px;
        top: 9px;
        right: 0;
      }
    }
  }
}

.column-text-wrap {
  font-family: g.$font-noto-sans;
  color: g.$font-base-color;
  font-weight: g.$font-weight-regular;

  @include g.media-query-pc {
    font-size: 1.8rem;
    line-height: 1.333;
    width: 458px;
  }

  @include g.media-query-sp {
    font-size: 1.5rem; //3rem??
    line-height: 1.4;
  }

  .column-text {

    +.column-text {
      margin-top: 20px;
    }
  }
}

.hotel-feature {
  @include g.media-query-pc {
    gap: 30px;
    align-items: center;
  }

  @include g.media-query-sp {
    flex-direction: column-reverse;
    gap: 16px;
  }

  .modal-image-wrap {
    @include g.media-query-pc {
      width: 490px;
    }
  }
}

.review {
  margin-top: 48px;

  @include g.media-query-pc {
    gap: 20px;
  }

  @include g.media-query-sp {
    flex-direction: column;
    gap: 35px;
    margin-top: 34px;
  }

  &-wrap {
    background-color: #fff;
    padding: 40px;
    border-radius: 20px;
    position: relative;

    ::before {
      content: '';
      background-image: url(.././img/main/illustration_01.png);
      width: 54px;
      height: 25px;
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translate(-50%);
      background-repeat: no-repeat;
    }

    @include g.media-query-sp {
      padding: 29px 15px 33px;
      border-radius: 0;
    }
  }

  &-body {
    font-family: g.$font-noto-serif;
    color: g.$font-base-color;
    font-weight: g.$font-weight-medium;
    border-bottom: 1px dashed #ccc;
    display: inline;
    padding-bottom: 2px;

    @include g.media-query-pc {
      font-size: 1.7rem;
      line-height: 1.941;
    }

    @include g.media-query-sp {
      font-size: 1.5rem;
      line-height: 1.933;
      letter-spacing: -0.05em;
    }
  }
}

.hotel-info {
  align-items: center;
  gap: 40px;
  justify-content: space-between;

  @include g.media-query-sp {
    flex-direction: column;
    gap: 20px;
  }

  .table-title {
    width: 115px;
    font-family: g.$font-noto-sans;
    font-weight: g.$font-weight-medium;

    @include g.media-query-sp {
      width: 97px;
    }
  }

  .table-info {
    font-family: g.$font-noto-sans;
    font-weight: g.$font-weight-regular;

    @include g.media-query-pc {
      flex: 1;
    }
  }
}

.modal-image-wrap {

  @include g.media-query-sp {
    width: 100%;
  }

  img {
    border-radius: 20px;
    width: 100%;

    @include g.media-query-sp {
      border-radius: 10px;
    }
  }
}

// .modal-map-wrap {
//   position: relative;
//   width: 100%;
//   height: 0;
//   padding-top: 49%;
// }

.modal-map-wrap {
  width: 100%;

  @include g.media-query-pc {
    max-width: 491px;
  }
}

.modal-map-wrap iframe {
  aspect-ratio: 2 / 1;
  border-radius: 20px;
  width: 100%;

  @include g.media-query-sp {
    border-radius: 10px;
  }
}