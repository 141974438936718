@use "../global" as g;

.button {
  color: #fff;
  font-size: 2rem;
  font-family: g.$font-noto-sans;
  line-height: 1.15;
  width: 100%;
  margin-top: auto;
  display: inline-block;

  @include g.media-query-sp {
    font-size: 1.6rem;
  }

  &-image {
    width: 620px;
  }

  &-top {
    text-align: center;
  }
}
.button-detail {
  background-color: g.$accent-color;
  text-align: center;
  border-radius: 5px;
  position: relative;

  // @include g.media-query-pc {
  //   &:not(.list-card-schedule .button-detail a),
  //   &:not(.section-reserve .button-detail a) {
  //     padding: 18px 0;
  //   }
  // }

  a {
    padding: 18px 0;
    overflow: unset;

    @include g.media-query-sp {
      padding: 15px 0;
    }

    &:focus {
      outline: none;
    }
  }

  ::after {
    @include g.up-down-center;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 0 7px 8px;
    border-color: transparent transparent transparent #fff;
    content: '';
    right: 40px;

    @include g.media-query-sp {
      right: 20px;
      border-width: 4px 0 4px 5px;
    }
  }
}

.list-card-schedule .button-detail a , .section-reserve .button-detail a {
  padding: 15px 0;
  
  @include g.media-query-sp {
    padding: 13px 0;
  }
}