// color
$accent-color: #d68d3d;
$font-base-color: #181818;
$point-color: #68a095;

//breakpoints
$breakpoints-spfirst: (
  'xs': 'screen and (min-width: 480px)',
  'sm': 'screen and (min-width: 768px)',
  'md': 'screen and (min-width: 992px)',
  'lg': 'screen and (min-width: 1200px)',
) !default;
$breakpoints-pcfirst: (
  'xs': 'screen and (max-width: 479px)',
  'sm': 'screen and (max-width: 767px)',
  'md': 'screen and (max-width: 991px)',
  'lg': 'screen and (max-width: 1199px)',
) !default;

//font
$font-noto-sans: "Noto Sans JP", "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
$font-noto-serif: "Noto Serif JP", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif;

//font-weight
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;

