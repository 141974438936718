@use "../global" as g;

.footer {
  padding: 10px;
}

.copyright {
  font-size: 1rem;
  text-align: center;
  color: g.$font-base-color;
  font-family: g.$font-noto-sans;
  font-weight: g.$font-weight-regular;
}
