@use "sass:map";
@use "sass:meta";
@use "sass:math";
@use "variable" as v;

//float解除用 clearfixの為にHTMLクラス付けてCSSで記述するよりCSSのみでmixinで呼び出せるようにした方が保守性向上
@mixin clearfix() {
  &::after {
    clear: both;
    content: '';
    display: block;
  }
}

// 使用するときの例（上下中央）
// .hoge {
//   @include g.clearfix;
// }


//positionで上下左右中央揃えにしたいとき用
@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

// 使用するときの例（上下左右中央）
// .hoge {
//   @include g.center;
// }

//positionで上下のみ中央揃えにしたいとき用
@mixin up-down-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
// 使用するときの例（上下中央）
// .hoge {
//   @include g.up-down-center;
// }

//リンクカラーの変更
@mixin linkColor($color) {
  color: $color;

  &:hover,
  &:active,
  &:focus {
    color: darken($color, 20%); //色を明るくしたいならdarkenをlightenに。
  }
}

// 使用するときの例（リンクカラー）
// a {
//   @include g.linkColor(rgb(255, 133, 51));
// }

//メディアクエリ
@mixin media-query-spfirst($breakpoint) {
  @if map-has-key(v.$breakpoints-spfirst, $breakpoint) {
    @media #{unquote(map.get(v.$breakpoints-spfirst, $breakpoint))} {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    + "Available breakpoints are: #{map.keys(v.$breakpoints-spfirst)}.";
  }
}

@mixin media-query-pcfirst($breakpoint) {
  @if map-has-key(v.$breakpoints-pcfirst, $breakpoint) {
    @media #{unquote(map.get(v.$breakpoints-pcfirst, $breakpoint))} {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    + "Available breakpoints are: #{map.keys(v.$breakpoints-pcfirst)}.";
  }
}

@mixin media-query-pc {
  @include media-query-spfirst(sm) {
    @content;
  }
}

@mixin media-query-sp {
  @include media-query-pcfirst(sm) {
    @content;
  }
}

//使用するときの例（メディアクエリ）
// .hoge {
//   @include g.media-query-pc {
//     background-color: red;
//   }
//   @include g.media-query-sp {
//     background: blue;
//   }
// }

@mixin clamp_fontsize($fs_min, $width_small, $width_large, $fs_max) {
  $preferred_vw_base: math.div(($fs_max - $fs_min), ($width_large - $width_small));
  $preferred_vw: ($preferred_vw_base * 100) * 1vw;
  $preferred_rem: ($width_small * -1 * $preferred_vw_base + $fs_min) * .1rem;
  font-size: clamp($fs_min * .1rem, $preferred_rem + $preferred_vw, $fs_max * .1rem);
}

//使用するときの例
//最小・最大フォントサイズをそれぞれ10px・18px、ブラウザの最小・最大幅をそれぞれ375px・768pxとしたい場合
// .hoge {
//   @include clamp_fontsize(10, 375, 768, 18);
// }


// @mixin lineHeightCrop($line-height) {
//   &::before {
//     display: block;
//     width: 0;
//     height: 0;
//     margin-top: calc((1 - #{$line-height}) * .5em);
//     content: "";
//   }
//   &::after {
//     display: block;
//     width: 0;
//     height: 0;
//     margin-bottom: calc((1 - #{$line-height}) * .5em);
//     content: "";
//   }
//  }

@mixin lhCrop($line-height:$base-line-height, $capital-letter: 1) {
  overflow:hidden; //相殺した余白をカット
  &::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((#{$capital-letter} - #{$line-height}) * 0.5em);
  }
&::after{
//最終行も取り除く
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((#{$capital-letter} - #{$line-height}) * 0.5em);
}
}