@use "../global" as g;

.list-card {
  display: flex;
  flex-wrap: wrap;

  @include g.media-query-sp {
    flex-direction: column;
  }

  .list-card-heading-wrapper {
    @include g.media-query-sp {
      img[src*="card-heading-hotel-01"] {
        width: calc((453 / 750) * 100vw);
      }

      img[src*="card-heading-hotel-02"] {
        width: calc((448 / 750) * 100vw);
      }
    }
  }

  &-one {
    align-items: end;
    gap: 64px 48px;
    margin-top: 60px;
    
    @include g.media-query-sp {
      gap: 29px;
      margin-top: 40px;
    }

    .list-card-heading-wrapper {
      align-items: end;
      gap: 14px;

      @include g.media-query-sp {
        gap: calc((16 / 750) * 100vw);
      }
    }

    @include g.media-query-sp {
      img[src*="section_heading_sp_01"] {
        width: calc((507 / 750) * 100vw);
      }

      img[src*="section_heading_sp_02"] {
        width: calc((633 / 750) * 100vw);
      }

      img[src*="section_heading_sp_03"] {
        width: calc((657 / 750) * 100vw);
      }

      img[src*="section_heading_sp_04"] {
        width: calc((693 / 750) * 100vw);
      }
    }

    >li {
      @include g.media-query-pc {
        width: calc((100% / 2) - 24px);
      }
    }

    li:nth-child(2) {
      .list-card-image-wrap {
        position: relative;
      }

      .list-card-decoration {
        position: absolute;
        top: -60px;
        left: -45px;
        width: 239px;

        @include g.media-query-sp {
          width: calc((336 / 750) * 100vw);
          top: -41px;
          left: -15px;
        }
      }
    }
  }

  &-heading-wrapper {
    display: flex;
  }

  &-thumbnail {
    border-radius: 20px;

    @include g.media-query-sp {
      border-radius: 10px;
    }

    &-02 {
      margin-top: 50px;

      @include g.media-query-sp {
        margin-top: 10px;
      }
    }
  }

  &-heading {
    font-size: 3rem;
    font-family: g.$font-noto-serif;
    color: g.$font-base-color;
    line-height: 1.267;
    font-weight: g.$font-weight-regular;
    letter-spacing: -.03em;

    @include g.media-query-sp {
      font-size: calc((30 / 750) * 100vw);
    }
  }

  &-description {
    font-size: 1.7rem;
    font-family: g.$font-noto-sans;
    color: g.$font-base-color;
    line-height: 1.353;
    margin: 27px 0 ;
    font-weight: g.$font-weight-regular;
    @include g.lhCrop(1.353);

    @include g.media-query-sp {
      line-height: 1.4;
      font-size: 1.5rem;
      margin: 20px 0;

      &:not(.section-spot .list-card-description) {
        margin: 12px 0 20px;
      }
    }
  }

  .section-reserve .list-card-decoration {
    @include g.media-query-pc {
      margin: 18px 0 30px 0;
    }
  }

  .section-spot .list-card-decoration {
    @include g.media-query-sp {
      margin: 20px 0;
    }
  }

  &-image-wrap {
    img {
      width: 100%;
    }
  }

  &-two {
    gap: 50px;
    margin-top: 40px;

    .list-card-description {
      @include g.media-query-pc {
        margin: 15px 0 27px;
      }
    }

    @include g.media-query-sp {
      margin-top: 25px;
      gap: 40px;
    }

    >li {
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 1;

      @include g.media-query-pc {
        width: calc((100% / 2) - 25px);

      }

      .list-card-heading-wrapper {
        @include g.media-query-pc {
          width: 349px;
        }
      }
    }

    .list-card-image-wrap {
      display: flex;
      gap: 5px;
      margin-top: 20px;

      @include g.media-query-sp {
        margin-top: 10px;
      }
    }

    .list-card-thumbnail {
      width: calc((100% / 2) - 5px);
    }
  }

  &-tree {
    gap: 25px;
    margin-top: 60px;

    @include g.media-query-sp {
      gap: 15px;
      margin-top: 25px;
    }

    >li {
      @include g.media-query-pc {
        width: calc((100% / 3) - 25px);
      }
    }

    .list-card-heading-wrapper {
      background-color: g.$point-color;
      padding: 12px 0;

      @include g.media-query-sp {
        padding: 9px 0;
      }

      h3 {
        margin: auto;
      }

      @include g.media-query-sp {

        img[src*="card-heading-01"] {
          width: calc((237 / 750) * 100vw);
        }

        img[src*="card-heading-02"] {
          width: calc((419 / 750) * 100vw);
        }

        img[src*="card-heading-03"] {
          width: calc((150 / 750) * 100vw);
        }
      }
    }

    .list-card-thumbnail {
      margin-top: 16px;

      @include g.media-query-sp {
        width: 100%;
        margin-top: 10px;
      }
    }
  }

  &-decoration-tree {
    margin: 16px 0 0 0;
  }

  &-schedule {
    gap: 19px;

    @include g.media-query-sp {
      padding-top: 17px;
    }

    @include g.media-query-pc {
      margin: 27px auto 0 115px;
    }

    .list-card-image-wrap {
      margin-bottom: 20px;

      img {
        width: 100%;
      }

      @include g.media-query-sp {
        margin-bottom: 6px;
      }
    }

    li {
      @include g.media-query-pc {
        width: 400px;
      }
    }
  }

  &-number {
    width: 78px;

    @include g.media-query-sp {
      width: calc((174 / 750) * 100vw);
    }
  }
}

.list-card-thumbnail-two {
  width: 430px;
}