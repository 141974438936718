@charset "UTF-8";
html {
  font-size: 62.5%;
}

body {
  font-family: "Noto Serif JP", serif;
  font-feature-settings: "palt";
}

a:hover {
  filter: brightness(80%);
  transition: 0.3s ease-in-out;
}

/*base*/
img {
  vertical-align: middle;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  line-height: 1.5;
}

/*reset*/
/* Box sizing rules */
/* Box sizingの定義 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* tableの余白削除 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Remove default padding */
/* デフォルトのpaddingを削除 */
ul,
ol {
  padding: 0;
}

/* Remove default margin */
/* デフォルトのmarginを削除 */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
/* bodyのデフォルトを定義 */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
/* class属性を持つul、ol要素のリストスタイルを削除 */
ul[class],
li,
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
/* classを持たない要素はデフォルトのスタイルを取得 */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
/* img要素の扱いを簡単にする */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
/* article要素内の要素に自然な流れとリズムを定義 */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
/* inputやbuttonなどのフォントは継承を定義 */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
/* 見たくない人用に、すべてのアニメーションとトランジションを削除 */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
@media screen and (min-width: 768px) {
  body {
    min-width: 1180px;
  }
}

.wrapper {
  overflow-x: hidden;
}

.inner {
  padding: 80px 20px;
  margin: 0 auto;
  max-width: 1180px;
}
@media screen and (max-width: 767px) {
  .inner:not(.section-inner-top) {
    padding: 40px 4vw;
  }
}

@media screen and (max-width: 767px) {
  .section-best-thing .inner {
    padding-right: 4vw;
    padding-left: 4vw;
    padding-bottom: 40px;
  }
}

.section-inner-top {
  padding-top: 150px;
}
@media screen and (max-width: 767px) {
  .section-inner-top {
    padding-top: 110px;
  }
}

.button {
  color: #fff;
  font-size: 2rem;
  font-family: "Noto Sans JP", "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  line-height: 1.15;
  width: 100%;
  margin-top: auto;
  display: inline-block;
}
@media screen and (max-width: 767px) {
  .button {
    font-size: 1.6rem;
  }
}
.button-image {
  width: 620px;
}
.button-top {
  text-align: center;
}

.button-detail {
  background-color: #d68d3d;
  text-align: center;
  border-radius: 5px;
  position: relative;
}
.button-detail a {
  padding: 18px 0;
  overflow: unset;
}
@media screen and (max-width: 767px) {
  .button-detail a {
    padding: 15px 0;
  }
}
.button-detail a:focus {
  outline: none;
}
.button-detail ::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 0 7px 8px;
  border-color: transparent transparent transparent #fff;
  content: "";
  right: 40px;
}
@media screen and (max-width: 767px) {
  .button-detail ::after {
    right: 20px;
    border-width: 4px 0 4px 5px;
  }
}

.list-card-schedule .button-detail a, .section-reserve .button-detail a {
  padding: 15px 0;
}
@media screen and (max-width: 767px) {
  .list-card-schedule .button-detail a, .section-reserve .button-detail a {
    padding: 13px 0;
  }
}

.list-card {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 767px) {
  .list-card {
    flex-direction: column;
  }
}
@media screen and (max-width: 767px) {
  .list-card .list-card-heading-wrapper img[src*=card-heading-hotel-01] {
    width: 60.4vw;
  }
  .list-card .list-card-heading-wrapper img[src*=card-heading-hotel-02] {
    width: 59.7333333333vw;
  }
}
.list-card-one {
  align-items: end;
  gap: 64px 48px;
  margin-top: 60px;
}
@media screen and (max-width: 767px) {
  .list-card-one {
    gap: 29px;
    margin-top: 40px;
  }
}
.list-card-one .list-card-heading-wrapper {
  align-items: end;
  gap: 14px;
}
@media screen and (max-width: 767px) {
  .list-card-one .list-card-heading-wrapper {
    gap: 2.1333333333vw;
  }
}
@media screen and (max-width: 767px) {
  .list-card-one img[src*=section_heading_sp_01] {
    width: 67.6vw;
  }
  .list-card-one img[src*=section_heading_sp_02] {
    width: 84.4vw;
  }
  .list-card-one img[src*=section_heading_sp_03] {
    width: 87.6vw;
  }
  .list-card-one img[src*=section_heading_sp_04] {
    width: 92.4vw;
  }
}
@media screen and (min-width: 768px) {
  .list-card-one > li {
    width: calc(50% - 24px);
  }
}
.list-card-one li:nth-child(2) .list-card-image-wrap {
  position: relative;
}
.list-card-one li:nth-child(2) .list-card-decoration {
  position: absolute;
  top: -60px;
  left: -45px;
  width: 239px;
}
@media screen and (max-width: 767px) {
  .list-card-one li:nth-child(2) .list-card-decoration {
    width: 44.8vw;
    top: -41px;
    left: -15px;
  }
}
.list-card-heading-wrapper {
  display: flex;
}
.list-card-thumbnail {
  border-radius: 20px;
}
@media screen and (max-width: 767px) {
  .list-card-thumbnail {
    border-radius: 10px;
  }
}
.list-card-thumbnail-02 {
  margin-top: 50px;
}
@media screen and (max-width: 767px) {
  .list-card-thumbnail-02 {
    margin-top: 10px;
  }
}
.list-card-heading {
  font-size: 3rem;
  font-family: "Noto Serif JP", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif;
  color: #181818;
  line-height: 1.267;
  font-weight: 400;
  letter-spacing: -0.03em;
}
@media screen and (max-width: 767px) {
  .list-card-heading {
    font-size: 4vw;
  }
}
.list-card-description {
  font-size: 1.7rem;
  font-family: "Noto Sans JP", "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  color: #181818;
  line-height: 1.353;
  margin: 27px 0;
  font-weight: 400;
  overflow: hidden;
}
.list-card-description::before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  margin-top: calc((1 - 1.353) * 0.5em);
}
.list-card-description::after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  margin-top: calc((1 - 1.353) * 0.5em);
}
@media screen and (max-width: 767px) {
  .list-card-description {
    line-height: 1.4;
    font-size: 1.5rem;
    margin: 20px 0;
  }
  .list-card-description:not(.section-spot .list-card-description) {
    margin: 12px 0 20px;
  }
}
@media screen and (min-width: 768px) {
  .list-card .section-reserve .list-card-decoration {
    margin: 18px 0 30px 0;
  }
}
@media screen and (max-width: 767px) {
  .list-card .section-spot .list-card-decoration {
    margin: 20px 0;
  }
}
.list-card-image-wrap img {
  width: 100%;
}
.list-card-two {
  gap: 50px;
  margin-top: 40px;
}
@media screen and (min-width: 768px) {
  .list-card-two .list-card-description {
    margin: 15px 0 27px;
  }
}
@media screen and (max-width: 767px) {
  .list-card-two {
    margin-top: 25px;
    gap: 40px;
  }
}
.list-card-two > li {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .list-card-two > li {
    width: calc(50% - 25px);
  }
}
@media screen and (min-width: 768px) {
  .list-card-two > li .list-card-heading-wrapper {
    width: 349px;
  }
}
.list-card-two .list-card-image-wrap {
  display: flex;
  gap: 5px;
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .list-card-two .list-card-image-wrap {
    margin-top: 10px;
  }
}
.list-card-two .list-card-thumbnail {
  width: calc(50% - 5px);
}
.list-card-tree {
  gap: 25px;
  margin-top: 60px;
}
@media screen and (max-width: 767px) {
  .list-card-tree {
    gap: 15px;
    margin-top: 25px;
  }
}
@media screen and (min-width: 768px) {
  .list-card-tree > li {
    width: calc(33.3333333333% - 25px);
  }
}
.list-card-tree .list-card-heading-wrapper {
  background-color: #68a095;
  padding: 12px 0;
}
@media screen and (max-width: 767px) {
  .list-card-tree .list-card-heading-wrapper {
    padding: 9px 0;
  }
}
.list-card-tree .list-card-heading-wrapper h3 {
  margin: auto;
}
@media screen and (max-width: 767px) {
  .list-card-tree .list-card-heading-wrapper img[src*=card-heading-01] {
    width: 31.6vw;
  }
  .list-card-tree .list-card-heading-wrapper img[src*=card-heading-02] {
    width: 55.8666666667vw;
  }
  .list-card-tree .list-card-heading-wrapper img[src*=card-heading-03] {
    width: 20vw;
  }
}
.list-card-tree .list-card-thumbnail {
  margin-top: 16px;
}
@media screen and (max-width: 767px) {
  .list-card-tree .list-card-thumbnail {
    width: 100%;
    margin-top: 10px;
  }
}
.list-card-decoration-tree {
  margin: 16px 0 0 0;
}
.list-card-schedule {
  gap: 19px;
}
@media screen and (max-width: 767px) {
  .list-card-schedule {
    padding-top: 17px;
  }
}
@media screen and (min-width: 768px) {
  .list-card-schedule {
    margin: 27px auto 0 115px;
  }
}
.list-card-schedule .list-card-image-wrap {
  margin-bottom: 20px;
}
.list-card-schedule .list-card-image-wrap img {
  width: 100%;
}
@media screen and (max-width: 767px) {
  .list-card-schedule .list-card-image-wrap {
    margin-bottom: 6px;
  }
}
@media screen and (min-width: 768px) {
  .list-card-schedule li {
    width: 400px;
  }
}
.list-card-number {
  width: 78px;
}
@media screen and (max-width: 767px) {
  .list-card-number {
    width: 23.2vw;
  }
}

.list-card-thumbnail-two {
  width: 430px;
}

.time-stamp-wrap {
  position: relative;
  padding-top: 10px;
  width: 79px;
}
@media screen and (max-width: 767px) {
  .time-stamp-wrap {
    width: 36px;
  }
}
.time-stamp-wrap::before {
  border-left: 2px dashed #68a095;
  content: "";
  position: absolute;
  width: 0;
  top: 20px;
  left: calc(100% + 50px);
  height: 100%;
  transform: translateX(-50%);
}
@media screen and (max-width: 767px) {
  .time-stamp-wrap::before {
    top: 15px;
    left: calc(100% + 20px);
  }
}
.time-stamp-wrap::after {
  content: "";
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #68a095;
  position: absolute;
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .time-stamp-wrap::after {
    top: 10px;
    left: calc(100% + 40px);
  }
}
@media screen and (max-width: 767px) {
  .time-stamp-wrap::after {
    top: 5px;
    right: -25px;
    width: 10px;
    height: 10px;
    border: 2px solid #68a095;
    top: 8px;
  }
}

/*!
	Modaal - accessible modals - v0.4.4
	by Humaan, for all humans.
	http://humaan.com
 */
.modaal-noscroll {
  overflow: hidden;
}

.modaal-accessible-hide {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.modaal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  opacity: 0;
}

.modaal-wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: auto;
  opacity: 1;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  transition: all 0.3s ease-in-out;
}
.modaal-wrapper * {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
}
.modaal-wrapper .modaal-close {
  border: none;
  background: transparent;
  padding: 0;
  -webkit-appearance: none;
}
.modaal-wrapper.modaal-start_none {
  display: none;
  opacity: 1;
}
.modaal-wrapper.modaal-start_fade {
  opacity: 0;
}
.modaal-wrapper *[tabindex="0"] {
  outline: none !important;
}
.modaal-wrapper.modaal-fullscreen {
  overflow: hidden;
}

.modaal-outer-wrapper {
  display: table;
  position: relative;
  width: 100%;
  height: 100%;
}
.modaal-fullscreen .modaal-outer-wrapper {
  display: block;
}

.modaal-inner-wrapper {
  display: table-cell;
  width: 100%;
  height: 100%;
  position: relative;
  vertical-align: middle;
  text-align: center;
  padding: 80px 25px;
}
.modaal-fullscreen .modaal-inner-wrapper {
  padding: 0;
  display: block;
  vertical-align: top;
}

.modaal-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: auto;
  text-align: left;
  color: #000;
  max-width: 1000px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  cursor: auto;
}
.modaal-container.is_loading {
  height: 100px;
  width: 100px;
  overflow: hidden;
}
.modaal-fullscreen .modaal-container {
  max-width: none;
  height: 100%;
  overflow: auto;
}

.modaal-close {
  position: fixed;
  right: 20px;
  top: 20px;
  color: #fff;
  cursor: pointer;
  opacity: 1;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}
.modaal-close:focus, .modaal-close:hover {
  outline: none;
  background: #fff;
}
.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #b93d0c;
}
.modaal-close span {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}
.modaal-close:before, .modaal-close:after {
  display: block;
  content: " ";
  position: absolute;
  top: 14px;
  left: 23px;
  width: 4px;
  height: 22px;
  border-radius: 4px;
  background: #fff;
  transition: background 0.2s ease-in-out;
}
.modaal-close:before {
  transform: rotate(-45deg);
}
.modaal-close:after {
  transform: rotate(45deg);
}
.modaal-fullscreen .modaal-close {
  background: #afb7bc;
  right: 10px;
  top: 10px;
}

.modaal-content-container {
  padding: 30px;
}

.modaal-confirm-wrap {
  padding: 30px 0 0;
  text-align: center;
  font-size: 0;
}

.modaal-confirm-btn {
  font-size: 14px;
  display: inline-block;
  margin: 0 10px;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  background: transparent;
}
.modaal-confirm-btn.modaal-ok {
  padding: 10px 15px;
  color: #fff;
  background: #555;
  border-radius: 3px;
  transition: background 0.2s ease-in-out;
}
.modaal-confirm-btn.modaal-ok:hover {
  background: #2f2f2f;
}
.modaal-confirm-btn.modaal-cancel {
  text-decoration: underline;
}
.modaal-confirm-btn.modaal-cancel:hover {
  text-decoration: none;
  color: #2f2f2f;
}

@keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.modaal-instagram .modaal-container {
  width: auto;
  background: transparent;
  box-shadow: none !important;
}
.modaal-instagram .modaal-content-container {
  padding: 0;
  background: transparent;
}
.modaal-instagram .modaal-content-container > blockquote {
  width: 1px !important;
  height: 1px !important;
  opacity: 0 !important;
}
.modaal-instagram iframe {
  opacity: 0;
  margin: -6px !important;
  border-radius: 0 !important;
  width: 1000px !important;
  max-width: 800px !important;
  box-shadow: none !important;
  animation: instaReveal 1s linear forwards;
}

.modaal-image .modaal-inner-wrapper {
  padding-left: 140px;
  padding-right: 140px;
}
.modaal-image .modaal-container {
  width: auto;
  max-width: 100%;
}

.modaal-gallery-wrap {
  position: relative;
  color: #fff;
}

.modaal-gallery-item {
  display: none;
}
.modaal-gallery-item img {
  display: block;
}
.modaal-gallery-item.is_active {
  display: block;
}

.modaal-gallery-label {
  position: absolute;
  left: 0;
  width: 100%;
  margin: 20px 0 0;
  font-size: 18px;
  text-align: center;
  color: #fff;
}
.modaal-gallery-label:focus {
  outline: none;
}

.modaal-gallery-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
  cursor: pointer;
  color: #fff;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}
.modaal-gallery-control.is_hidden {
  opacity: 0;
  cursor: default;
}
.modaal-gallery-control:focus, .modaal-gallery-control:hover {
  outline: none;
  background: #fff;
}
.modaal-gallery-control:focus:before, .modaal-gallery-control:focus:after, .modaal-gallery-control:hover:before, .modaal-gallery-control:hover:after {
  background: #afb7bc;
}
.modaal-gallery-control span {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}
.modaal-gallery-control:before, .modaal-gallery-control:after {
  display: block;
  content: " ";
  position: absolute;
  top: 16px;
  left: 25px;
  width: 4px;
  height: 18px;
  border-radius: 4px;
  background: #fff;
  transition: background 0.2s ease-in-out;
}
.modaal-gallery-control:before {
  margin: -5px 0 0;
  transform: rotate(-45deg);
}
.modaal-gallery-control:after {
  margin: 5px 0 0;
  transform: rotate(45deg);
}

.modaal-gallery-next-inner {
  left: 100%;
  margin-left: 40px;
}
.modaal-gallery-next-outer {
  right: 45px;
}

.modaal-gallery-prev:before, .modaal-gallery-prev:after {
  left: 22px;
}
.modaal-gallery-prev:before {
  margin: 5px 0 0;
  transform: rotate(-45deg);
}
.modaal-gallery-prev:after {
  margin: -5px 0 0;
  transform: rotate(45deg);
}
.modaal-gallery-prev-inner {
  right: 100%;
  margin-right: 40px;
}
.modaal-gallery-prev-outer {
  left: 45px;
}

.modaal-video-wrap {
  margin: auto 50px;
  position: relative;
}

.modaal-video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: #000;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}
.modaal-video-container iframe,
.modaal-video-container object,
.modaal-video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modaal-iframe .modaal-content {
  width: 100%;
  height: 100%;
}

.modaal-iframe-elem {
  width: 100%;
  height: 100%;
  display: block;
}

@media only screen and (min-width: 1400px) {
  .modaal-video-container {
    padding-bottom: 0;
    height: 731px;
  }
}
@media only screen and (max-width: 1140px) {
  .modaal-image .modaal-inner-wrapper {
    padding-left: 25px;
    padding-right: 25px;
  }
  .modaal-gallery-control {
    top: auto;
    bottom: 20px;
    transform: none;
    background: rgba(0, 0, 0, 0.7);
  }
  .modaal-gallery-control:before, .modaal-gallery-control:after {
    background: #fff;
  }
  .modaal-gallery-next {
    left: auto;
    right: 20px;
  }
  .modaal-gallery-prev {
    left: 20px;
    right: auto;
  }
}
@media screen and (max-width: 900px) {
  .modaal-instagram iframe {
    width: 500px !important;
  }
}
@media screen and (max-height: 1100px) {
  .modaal-instagram iframe {
    width: 700px !important;
  }
}
@media screen and (max-height: 1000px) {
  .modaal-inner-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .modaal-instagram iframe {
    width: 600px !important;
  }
}
@media screen and (max-height: 900px) {
  .modaal-instagram iframe {
    width: 500px !important;
  }
  .modaal-video-container {
    max-width: 900px;
    max-height: 510px;
  }
}
@media only screen and (max-width: 600px) {
  .modaal-instagram iframe {
    width: 280px !important;
  }
}
@media only screen and (max-height: 820px) {
  .modaal-gallery-label {
    display: none;
  }
}
.modaal-loading-spinner {
  background: none;
  position: absolute;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -100px;
  transform: scale(0.25);
}

@-ms-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.modaal-loading-spinner > div {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  margin-top: 4px;
  position: absolute;
}

.modaal-loading-spinner > div > div {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: #fff;
}

.modaal-loading-spinner > div:nth-of-type(1) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

.modaal-loading-spinner > div:nth-of-type(2) > div, .modaal-loading-spinner > div:nth-of-type(3) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(1) {
  -ms-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(2) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.12s;
  -moz-animation-delay: 0.12s;
  -webkit-animation-delay: 0.12s;
  -o-animation-delay: 0.12s;
  animation-delay: 0.12s;
}

.modaal-loading-spinner > div:nth-of-type(2) {
  -ms-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(3) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.25s;
  -moz-animation-delay: 0.25s;
  -webkit-animation-delay: 0.25s;
  -o-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.modaal-loading-spinner > div:nth-of-type(4) > div, .modaal-loading-spinner > div:nth-of-type(5) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(3) {
  -ms-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(4) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.37s;
  -moz-animation-delay: 0.37s;
  -webkit-animation-delay: 0.37s;
  -o-animation-delay: 0.37s;
  animation-delay: 0.37s;
}

.modaal-loading-spinner > div:nth-of-type(4) {
  -ms-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(5) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.modaal-loading-spinner > div:nth-of-type(6) > div, .modaal-loading-spinner > div:nth-of-type(7) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(5) {
  -ms-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(6) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.62s;
  -moz-animation-delay: 0.62s;
  -webkit-animation-delay: 0.62s;
  -o-animation-delay: 0.62s;
  animation-delay: 0.62s;
}

.modaal-loading-spinner > div:nth-of-type(6) {
  -ms-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(7) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;
  -webkit-animation-delay: 0.75s;
  -o-animation-delay: 0.75s;
  animation-delay: 0.75s;
}

.modaal-loading-spinner > div:nth-of-type(7) {
  -ms-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(8) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.87s;
  -moz-animation-delay: 0.87s;
  -webkit-animation-delay: 0.87s;
  -o-animation-delay: 0.87s;
  animation-delay: 0.87s;
}

.modaal-loading-spinner > div:nth-of-type(8) {
  -ms-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
}

#modal {
  display: none;
}

.modaal-content-container {
  overflow-X: hidden;
}

.em-color {
  color: #d68d3d;
  font-size: 1.33em;
  font-style: normal;
}

.kv-wrapper {
  background-size: auto;
  background-position: top;
  background-repeat: no-repeat;
  z-index: 1;
  position: relative;
  pointer-events: none;
}
@media screen and (min-width: 768px) {
  .kv-wrapper {
    height: 1080px;
  }
}
@media screen and (max-width: 767px) {
  .kv-wrapper {
    height: 627px;
    background-size: auto 100%;
  }
}
.kv-wrapper .kv-bg {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 2884px;
}
@media screen and (max-width: 767px) {
  .kv-wrapper .kv-bg {
    min-width: 750px;
    width: 100%;
  }
}

.kv-item {
  position: absolute;
}
@media screen and (min-width: 768px) {
  .kv-item-heading {
    top: 0;
    width: 661px;
    top: 85px;
    left: calc(50% - 617px);
  }
}
@media screen and (max-width: 767px) {
  .kv-item-heading {
    max-width: 340px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
}
.kv-item-01 {
  top: 480px;
  right: calc(50% - 641px);
}
@media screen and (max-width: 767px) {
  .kv-item-01 {
    max-width: 278px;
    width: 100%;
    right: 0;
  }
}
.kv-item-02 {
  top: 0;
}
@media screen and (min-width: 768px) {
  .kv-item-02 {
    right: calc(50% - 727px);
  }
}
@media screen and (max-width: 767px) {
  .kv-item-02 {
    left: -3px;
    max-width: 185px;
    width: 60%;
  }
}

.section-best-thing {
  background-size: auto 100%;
  background-position: top;
  background-repeat: no-repeat;
}
@media screen and (min-width: 768px) {
  .section-best-thing {
    margin-top: -290px;
    background-image: url(.././img/main/bg_01.png);
  }
}
@media screen and (max-width: 767px) {
  .section-best-thing {
    margin-top: -27px;
    background-image: url(.././img/main/bg_sp_01.png);
    background-size: 100% 100%;
  }
}
.section-best-thing .section-heading-wrap {
  margin-top: 60px;
}

@media screen and (max-width: 767px) {
  .section-heading img[src*=heading_01] {
    width: 74.8vw;
  }
  .section-heading img[src*=heading_02] {
    width: 92vw;
  }
  .section-heading img[src*=heading_03] {
    width: 90.4vw;
  }
  .section-heading img[src*=heading_sp_04] {
    width: 66.1333333333vw;
  }
  .section-heading img[src*=title-01] {
    width: 63.2vw;
  }
  .section-heading img[src*=heading_05] {
    width: 47.2vw;
  }
  .section-heading img[src*=heading_06] {
    width: 90.2666666667vw;
  }
  .section-heading img[src*=heading_sp_07] {
    width: 66vw;
  }
}

.section-heading-sub {
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .section-heading-sub img[src*=title-02] {
    width: 64.1333333333vw;
  }
}
.section-heading-sub::before, .section-heading-sub::after {
  position: absolute;
  content: "";
  background-repeat: no-repeat;
  background-size: 100%;
  bottom: 1px;
}
@media screen and (max-width: 767px) {
  .section-heading-sub::before, .section-heading-sub::after {
    width: 78px;
    height: 24px;
    top: 25px;
  }
}
.section-heading-sub::before {
  background-image: url(.././img/main/mountain_01.png);
  width: 177px;
  height: 54px;
  left: 168px;
}
@media screen and (max-width: 767px) {
  .section-heading-sub::before {
    width: 20.6666666667vw;
    height: 24px;
    left: -15px;
  }
}
.section-heading-sub::after {
  background-image: url(.././img/main/mountain_02.png);
  width: 274px;
  height: 63px;
  right: 71px;
}
@media screen and (max-width: 767px) {
  .section-heading-sub::after {
    right: -40px;
    width: 23.4666666667vw;
    height: 28px;
  }
}

.announce-image {
  margin: 30px auto 0;
}
.announce-image img {
  margin: auto;
}
@media screen and (min-width: 768px) {
  .announce-image {
    width: 647px;
  }
}
@media screen and (max-width: 767px) {
  .announce-image {
    margin-top: 20px;
  }
  .announce-image img[src*=privilege_sp] {
    width: 76.1333333333vw;
  }
}

@media screen and (max-width: 767px) {
  .section-inner-top img[src*=button_01] {
    width: 92vw;
  }
}
.section-inner-top .button {
  margin: auto;
}

.section-heading {
  position: relative;
  z-index: 1;
}
.section-heading::before, .section-heading::after {
  content: "";
  position: absolute;
  width: 166px;
  height: 101px;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767px) {
  .section-heading::before, .section-heading::after {
    width: 83px;
    height: 50px;
    background-size: 100%;
    top: -9px;
  }
}
.section-heading img {
  position: relative;
  z-index: 1;
  margin: auto;
}

.heading-decoration-blue::before {
  top: 0;
  left: 20px;
  background-image: url(.././img/main/decoration_01.png);
}
@media screen and (max-width: 767px) {
  .heading-decoration-blue::before {
    left: -5px;
  }
}
.heading-decoration-blue::after {
  top: 132px;
  right: 20px;
  background-image: url(.././img/main/decoration_02.png);
}
@media screen and (max-width: 767px) {
  .heading-decoration-blue::after {
    right: -5px;
  }
}

@media screen and (max-width: 767px) {
  .heading-decoration-orange::before, .heading-decoration-orange::after {
    width: 60px;
    height: 40px;
    background-size: 100%;
  }
}
.heading-decoration-orange::before {
  top: 0;
  left: 0;
  background-image: url(.././img/main/decoration_09.png);
}
@media screen and (max-width: 767px) {
  .heading-decoration-orange::before {
    top: -21px;
    background-image: url(.././img/main/decoration_11.png);
    top: 16px;
    left: -10px;
  }
}
.heading-decoration-orange::after {
  top: 89px;
  right: 0;
  background-image: url(.././img/main/decoration_10.png);
}
@media screen and (max-width: 767px) {
  .heading-decoration-orange::after {
    top: 46px;
    right: -10px;
  }
}

@media screen and (max-width: 767px) {
  .heading-decoration-blue-02::before {
    background-image: url(.././img/main/decoration_03.png);
    height: 107px;
    width: 67px;
    left: 0;
    top: -5px;
  }
}
@media screen and (min-width: 768px) {
  .heading-decoration-blue-02::after {
    top: 89px;
  }
}
@media screen and (max-width: 767px) {
  .heading-decoration-blue-02::after {
    background-image: url(.././img/main/decoration_05.png);
    height: 111px;
    width: 93px;
    top: -8px;
  }
}

.section-spot .heading-decoration-blue::before {
  top: 50px;
}
@media screen and (max-width: 767px) {
  .section-spot .heading-decoration-blue::before {
    top: -26px;
  }
}
.section-spot .heading-decoration-blue::after {
  top: 139px;
  right: -10px;
}

@media screen and (min-width: 768px) {
  .section-time-schedule .heading-decoration-blue-02::before {
    top: -53px;
  }
}
@media screen and (min-width: 768px) {
  .section-time-schedule .heading-decoration-blue-02::after {
    top: 37px;
  }
}

.section-heading > img {
  margin: auto;
}

.section-heading-sub {
  margin-top: 60px;
}
@media screen and (max-width: 767px) {
  .section-heading-sub {
    margin: 25px auto 20px;
  }
}
.section-heading-sub > img {
  margin: auto;
}

.section-heading-wrap {
  text-align: center;
}

.section-lead {
  font-size: 1.8rem;
  font-family: "Noto Sans JP", "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  color: #181818;
  line-height: 1.333;
  margin-top: 30px;
}
@media screen and (max-width: 767px) {
  .section-lead {
    font-size: 1.5rem;
    margin-top: 20px;
  }
}

.comment-list {
  font-weight: 400;
  background-color: #fff;
  position: relative;
}
@media screen and (min-width: 768px) {
  .comment-list {
    width: calc(50% - 15px);
    padding: 0 30px 37px;
  }
}
@media screen and (max-width: 767px) {
  .comment-list {
    padding: 0 15px 25px;
  }
}
.comment-list-wrap {
  display: flex;
}
@media screen and (min-width: 768px) {
  .comment-list-wrap {
    flex-wrap: wrap;
    margin-top: 70px;
    gap: 50px 30px;
  }
}
@media screen and (max-width: 767px) {
  .comment-list-wrap {
    margin-top: 40px;
    flex-direction: column;
    gap: 35px;
  }
}
.comment-list-text {
  font-family: "Noto Sans JP", "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  color: #181818;
  font-size: 1.8rem;
  line-height: 1.333;
  margin-top: 17px;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .comment-list-text {
    font-size: 1.5rem;
    overflow: hidden;
    line-height: 1.4;
    margin-top: 15px;
  }
  .comment-list-text::before {
    content: "";
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((1 - 1.4) * 0.5em);
  }
  .comment-list-text::after {
    content: "";
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((1 - 1.4) * 0.5em);
  }
}
.comment-list-item {
  margin-top: -10px;
}
@media screen and (max-width: 767px) {
  .comment-list-item {
    width: 65px;
    align-self: flex-start;
    margin-top: -5px;
  }
}
.comment-list-inner {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}
@media screen and (max-width: 767px) {
  .comment-list-inner {
    gap: 10px;
  }
}

.speech-bubble {
  background-color: #68a095;
  padding: 16px 30px;
  color: #fff;
  font-family: "Noto Serif JP", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif;
  font-weight: 500;
  font-size: 2.2rem;
  border-radius: 10px;
  position: relative;
  margin-top: -20px;
  line-height: 1.364;
  letter-spacing: -0.04em;
}
@media screen and (max-width: 767px) {
  .speech-bubble {
    font-size: 1.7rem;
    padding: 12px;
    width: 64vw;
  }
}
.speech-bubble ::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 38px;
  bottom: -10px;
  border-style: solid;
  border-color: transparent transparent transparent #68a095;
  border-width: 7px 0 7px 21.32px;
  transform: rotate(110deg);
}
.speech-bubble p {
  overflow: hidden;
}
.speech-bubble p::before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  margin-top: calc((1 - 1.364) * 0.5em);
}
.speech-bubble p::after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  margin-top: calc((1 - 1.364) * 0.5em);
}

.section-spot {
  background-color: #f5f9fd;
}

.section-contents {
  display: flex;
  gap: 30px;
  margin: 40px auto 80px;
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .section-contents {
    align-items: center;
  }
}
@media screen and (max-width: 767px) {
  .section-contents {
    flex-direction: column-reverse;
    margin: 17px auto 40px;
    gap: 25px;
  }
}
.section-contents-image-wrap {
  display: flex;
}
@media screen and (min-width: 768px) {
  .section-contents-image-wrap {
    width: 628px;
  }
}
.section-contents-text {
  font-size: 2rem;
  font-family: "Noto Serif JP", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif;
  color: #181818;
  font-weight: 600;
  line-height: 1.7;
  letter-spacing: -0.05em;
}
.section-contents-text + .section-contents-text {
  margin-top: 25px;
}
@media screen and (min-width: 768px) {
  .section-contents-text {
    width: 478px;
  }
}
@media screen and (max-width: 767px) {
  .section-contents-text {
    font-size: 1.5rem;
    line-height: 1.467;
  }
}
@media screen and (min-width: 768px) {
  .section-contents-text-bottom {
    margin-top: 40px;
  }
}
.section-contents-image-01 {
  border-radius: 20px;
}
.section-contents-image-02 {
  transform: translateX(-55px);
}

@media screen and (max-width: 767px) {
  .line-image {
    width: 301px;
    margin: auto;
  }
}
.line-image > img {
  margin: auto;
}

.section-reserve {
  background-color: #f3e7e1;
}

.section-time-schedule {
  background-color: #f5f9fd;
}

.label {
  background-color: #68a095;
  width: 100%;
  margin: 40px 0;
  padding: 8px 0;
}
@media screen and (max-width: 767px) {
  .label {
    padding: 7px 0;
    margin: 0 0 20px 0;
  }
}
.label .day-one,
.label .day-two {
  margin: auto;
}
@media screen and (max-width: 767px) {
  .label .day-one,
  .label .day-two {
    width: 50px;
  }
}

.schedule-body {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .schedule-body {
    padding-left: 65px;
  }
  .schedule-body-1day {
    padding-bottom: 30px;
  }
}
.schedule-course {
  position: relative;
}
.schedule-course-05 .guide-image {
  top: -29px;
}
@media screen and (min-width: 768px) {
  .schedule-course-06 .guide-contents {
    padding-bottom: 0;
  }
}
.schedule-course-06 .time-stamp-wrap::before {
  display: none;
}
.schedule-course-08 .guide-text {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .schedule-course-08 .guide-contents {
    padding-bottom: 160px;
  }
}
@media screen and (min-width: 768px) {
  .schedule-course-09 .guide {
    width: auto;
  }
}
.schedule-course-09 .list-card-description {
  margin: 15px 0;
}
@media screen and (max-width: 767px) {
  .schedule-course-09 .list-card-description {
    font-size: 1.5rem;
  }
}
.schedule-course-09 .list-card-schedule {
  gap: 24px;
}
@media screen and (min-width: 768px) {
  .schedule-course-09 .list-card-schedule :nth-of-type(2) {
    width: 430px;
  }
}
.schedule-course-09 .list-card-image-wrap img {
  border-radius: 0;
}
@media screen and (min-width: 768px) {
  .schedule-course-11 .guide-contents {
    padding-bottom: 0;
  }
}
.schedule-course-11 .time-stamp-wrap::before {
  display: none;
}
.schedule-item-wrap {
  display: flex;
}

img[src*=time_06] {
  width: 65px;
  margin-left: auto;
}
@media screen and (max-width: 767px) {
  img[src*=time_06] {
    width: 30px;
  }
}

.guide {
  position: relative;
}
@media screen and (min-width: 768px) {
  .guide {
    margin: 0 30px 0 120px;
    width: 546px;
  }
}
.guide-contents {
  padding-bottom: 72px;
}
@media screen and (max-width: 767px) {
  .guide-contents {
    padding-left: 39px;
    flex: 1;
    padding-bottom: 35px;
  }
}
.guide-em {
  font-family: "Noto Serif JP", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif;
  color: #181818;
  line-height: 1.267;
  font-weight: 500;
  overflow: hidden;
}
.guide-em::before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  margin-top: calc((1 - 1.267) * 0.5em);
}
.guide-em::after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  margin-top: calc((1 - 1.267) * 0.5em);
}
@media screen and (min-width: 768px) {
  .guide-em {
    font-size: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .guide-em {
    font-size: 1.8rem;
    letter-spacing: -0.07em;
  }
}
.guide-text {
  font-size: 1.7rem;
  font-family: "Noto Sans JP", "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  color: #181818;
  line-height: 1.353;
  font-weight: 400;
  margin-top: 20px;
  overflow: hidden;
}
.guide-text::before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  margin-top: calc((1 - 1.353) * 0.5em);
}
.guide-text::after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  margin-top: calc((1 - 1.353) * 0.5em);
}
@media screen and (min-width: 768px) {
  .guide-text {
    width: 546px;
  }
}
@media screen and (max-width: 767px) {
  .guide-text {
    font-size: 1.5rem;
    margin-top: 15px;
  }
}
@media screen and (min-width: 768px) {
  .guide-image {
    position: absolute;
    right: 0;
    top: 0;
    width: 300px;
  }
}
@media screen and (max-width: 767px) {
  .guide-image {
    margin-top: 15px;
  }
}
.guide-image :nth-child(2) {
  margin-top: 5px;
}
.guide-image img {
  border-radius: 20px;
}
@media screen and (max-width: 767px) {
  .guide-image img {
    width: 100%;
    border-radius: 10px;
  }
}

.table {
  font-family: "Noto Sans JP", "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}
.table dl {
  display: flex;
  align-items: center;
  gap: 11px;
  margin-top: 5px;
}
.table dl:first-child {
  margin-top: 0;
}
@media screen and (max-width: 767px) {
  .table dl {
    margin-top: 8px;
    gap: 5px;
  }
}
.table-title {
  background-color: #68a095;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.438;
  text-align: center;
  width: 80px;
  border-radius: 5px;
  padding: 4px 0;
  align-self: flex-start;
}
@media screen and (max-width: 767px) {
  .table-title {
    font-size: 1.4rem;
    line-height: 0.821;
    width: 97px;
    border-radius: 2.5px;
    padding: 7px 0;
  }
  .table-title:not(.hotel-info .table-title) {
    width: 67px;
  }
}
.table-info {
  line-height: 1.353;
  font-size: 1.7rem;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .table-info {
    font-size: 1.4rem;
    flex: 1;
  }
}
.table tbody {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.section-impression {
  background-image: url(.././img/main/bg_01.png);
  background-size: auto;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767px) {
  .section-impression {
    background-image: url(.././img/main/bg_sp_02.png);
  }
}

.message {
  font-family: "Noto Sans JP", "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  color: #181818;
  line-height: 1.941;
  font-weight: 400;
  border-bottom: 1px dashed #ccc;
  display: inline;
  padding-bottom: 2px;
}
@media screen and (min-width: 768px) {
  .message {
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 767px) {
  .message {
    font-size: 1.5rem;
  }
}
.message-column {
  position: relative;
}
@media screen and (min-width: 768px) {
  .message-column {
    justify-content: space-between;
    margin-top: -94px;
    display: flex;
  }
}
@media screen and (max-width: 767px) {
  .message-column {
    margin-top: 25px;
  }
}
@media screen and (max-width: 767px) {
  .message-column :nth-of-type(2) {
    margin-top: 20px;
  }
}
.message-wrap {
  background-color: #fff;
  padding: 40px 40px 50px 40px;
}
@media screen and (min-width: 768px) {
  .message-wrap {
    width: 340px;
  }
}
.message-title {
  background-color: #68a095;
  color: #fff;
  font-family: "Noto Serif JP", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif;
  line-height: 1.364;
  font-weight: 500;
  position: relative;
}
@media screen and (min-width: 768px) {
  .message-title {
    font-size: 2.2rem;
    padding: 11px 18px;
    margin-bottom: 23px;
  }
}
@media screen and (max-width: 767px) {
  .message-title {
    padding: 22px 0;
    text-align: center;
    font-size: 1.8rem;
  }
}
.message-title::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  right: 75px;
  bottom: -13px;
  border-style: solid;
  border-color: transparent transparent transparent #68a095;
  border-width: 7px 0 7px 21.32px;
  transform: rotate(65deg);
}

.message-column .message-wrap:nth-of-type(2) .message-title::after {
  left: 75px;
  transform: rotate(115deg);
}

.pair-people-image-01 {
  width: 263px;
  bottom: 0;
  left: 26%;
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .pair-people-image-01 {
    position: absolute;
  }
}

.pair-people-image-02 {
  width: 315px;
  bottom: 0;
  right: 26%;
}
@media screen and (min-width: 768px) {
  .pair-people-image-02 {
    position: absolute;
  }
}

.section-others {
  background-image: url(.././img/main/bg_02.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: top;
}
@media screen and (max-width: 767px) {
  .section-others {
    background-image: url(.././img/main/bg_sp_03.png);
    background-size: 100% 100%;
  }
}

.img {
  background-image: url("img.png");
  opacity: 0.4;
  position: absolute;
  left: -904px;
  top: 790px;
  width: 5021px;
  height: 1193px;
  z-index: 586;
}

.modal-inline {
  display: none;
}

.modaal-container {
  max-width: 1140px;
}

@media screen and (max-width: 767px) {
  .modaal-inner-wrapper {
    padding: 80px 15px;
  }
}

.modaal-content-container {
  background-color: #f5f9fd;
}
@media screen and (min-width: 768px) {
  .modaal-content-container {
    padding: 80px 80px 100px 80px;
  }
}
@media screen and (max-width: 767px) {
  .modaal-content-container {
    padding: 75px 4vw 40px;
  }
}

.modaal-close {
  display: none;
}

.modal-close {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  width: 60px;
  height: 60px;
}
@media screen and (max-width: 767px) {
  .modal-close {
    right: 15px;
    top: 15px;
    width: 50px;
    height: 50px;
  }
}

@media screen and (min-width: 768px) {
  .modal-contents .button-detail {
    width: 545px;
  }
}
@media screen and (max-width: 767px) {
  .modal-contents .button-detail {
    max-width: 630px;
    width: 100%;
  }
}

.modal-button {
  overflow: hidden;
}
.modal-button::before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  margin-top: calc((1 - 1.15) * 0.5em);
}
.modal-button::after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  margin-top: calc((1 - 1.15) * 0.5em);
}
@media screen and (max-width: 767px) {
  .modal-button {
    line-height: 0.938;
    overflow: hidden;
  }
  .modal-button::before {
    content: "";
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((1 - 0.938) * 0.5em);
  }
  .modal-button::after {
    content: "";
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((1 - 0.938) * 0.5em);
  }
}

@media screen and (min-width: 768px) {
  .modal-button-top {
    margin: 30px auto 40px auto;
  }
}
@media screen and (max-width: 767px) {
  .modal-button-top {
    margin: 20px auto;
  }
}

@media screen and (min-width: 768px) {
  .modal-button-bottom {
    margin: 40px auto 60px auto;
  }
}
@media screen and (max-width: 767px) {
  .modal-button-bottom {
    margin: 25px auto 50px auto;
  }
}

.column {
  display: flex;
}
.column-tree {
  gap: 10px;
}
@media screen and (max-width: 767px) {
  .column-tree {
    flex-direction: column;
  }
}
@media screen and (min-width: 768px) {
  .column-tree img {
    width: 320px;
  }
}

.modal-heading-01 .section-lead {
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .modal-heading-01 .section-lead {
    text-align: left;
    margin-top: 25px;
  }
}
@media screen and (max-width: 767px) {
  .modal-heading-01 .section-heading {
    max-width: 230px;
    margin: auto;
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .modal-heading-02 .section-heading {
    padding: 80px 0 50px 0;
  }
}
@media screen and (max-width: 767px) {
  .modal-heading-02 .section-heading {
    padding: 50px 0 30px 0;
    max-width: 309px;
    margin: auto;
    width: 100%;
  }
}

.modal-heading-01 .heading-decoration-blue:before {
  top: -14px;
  left: 60px;
}
@media screen and (max-width: 767px) {
  .modal-heading-01 .heading-decoration-blue:before {
    background-image: url(.././img/main/decoration_06.png);
    width: 63px;
    height: 50px;
    top: -35px;
    left: -42px;
  }
}
.modal-heading-01 .heading-decoration-blue::after {
  top: 6px;
  right: 61px;
}
@media screen and (max-width: 767px) {
  .modal-heading-01 .heading-decoration-blue::after {
    background-image: url(.././img/main/decoration_07.png);
    width: 53px;
    height: 61px;
    top: 8px;
    right: -43px;
  }
}

.modal-heading-02 .heading-decoration-blue::before {
  top: 10px;
  left: 0;
}
@media screen and (max-width: 767px) {
  .modal-heading-02 .heading-decoration-blue::before {
    background-image: url(.././img/main/decoration_08.png);
    width: 49px;
    height: 89px;
  }
}
.modal-heading-02 .heading-decoration-blue::after {
  top: 60px;
  right: 0;
}
@media screen and (max-width: 767px) {
  .modal-heading-02 .heading-decoration-blue::after {
    background-image: url(.././img/main/decoration_04.png);
    width: 83px;
    height: 78px;
    top: 9px;
    right: 0;
  }
}

.column-text-wrap {
  font-family: "Noto Sans JP", "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  color: #181818;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .column-text-wrap {
    font-size: 1.8rem;
    line-height: 1.333;
    width: 458px;
  }
}
@media screen and (max-width: 767px) {
  .column-text-wrap {
    font-size: 1.5rem;
    line-height: 1.4;
  }
}
.column-text-wrap .column-text + .column-text {
  margin-top: 20px;
}

@media screen and (min-width: 768px) {
  .hotel-feature {
    gap: 30px;
    align-items: center;
  }
}
@media screen and (max-width: 767px) {
  .hotel-feature {
    flex-direction: column-reverse;
    gap: 16px;
  }
}
@media screen and (min-width: 768px) {
  .hotel-feature .modal-image-wrap {
    width: 490px;
  }
}

.review {
  margin-top: 48px;
}
@media screen and (min-width: 768px) {
  .review {
    gap: 20px;
  }
}
@media screen and (max-width: 767px) {
  .review {
    flex-direction: column;
    gap: 35px;
    margin-top: 34px;
  }
}
.review-wrap {
  background-color: #fff;
  padding: 40px;
  border-radius: 20px;
  position: relative;
}
.review-wrap ::before {
  content: "";
  background-image: url(.././img/main/illustration_01.png);
  width: 54px;
  height: 25px;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translate(-50%);
  background-repeat: no-repeat;
}
@media screen and (max-width: 767px) {
  .review-wrap {
    padding: 29px 15px 33px;
    border-radius: 0;
  }
}
.review-body {
  font-family: "Noto Serif JP", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif;
  color: #181818;
  font-weight: 500;
  border-bottom: 1px dashed #ccc;
  display: inline;
  padding-bottom: 2px;
}
@media screen and (min-width: 768px) {
  .review-body {
    font-size: 1.7rem;
    line-height: 1.941;
  }
}
@media screen and (max-width: 767px) {
  .review-body {
    font-size: 1.5rem;
    line-height: 1.933;
    letter-spacing: -0.05em;
  }
}

.hotel-info {
  align-items: center;
  gap: 40px;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .hotel-info {
    flex-direction: column;
    gap: 20px;
  }
}
.hotel-info .table-title {
  width: 115px;
  font-family: "Noto Sans JP", "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .hotel-info .table-title {
    width: 97px;
  }
}
.hotel-info .table-info {
  font-family: "Noto Sans JP", "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .hotel-info .table-info {
    flex: 1;
  }
}

@media screen and (max-width: 767px) {
  .modal-image-wrap {
    width: 100%;
  }
}
.modal-image-wrap img {
  border-radius: 20px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .modal-image-wrap img {
    border-radius: 10px;
  }
}

.modal-map-wrap {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .modal-map-wrap {
    max-width: 491px;
  }
}

.modal-map-wrap iframe {
  aspect-ratio: 2/1;
  border-radius: 20px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .modal-map-wrap iframe {
    border-radius: 10px;
  }
}

.footer {
  padding: 10px;
}

.copyright {
  font-size: 1rem;
  text-align: center;
  color: #181818;
  font-family: "Noto Sans JP", "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-weight: 400;
}

@media screen and (min-width: 768px) {
  /* pc */
  .u-hidden-pc {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  /* sp */
  .u-hidden-sp {
    display: none;
  }
}
.letter-spacing-01 {
  letter-spacing: -0.25em;
}